import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import MainContent from "../components/MainContent"
import RichText from "../components/RichText"
import Header from '../components/Header';
import Footer from '../components/Footer';

function Page({ location, data }) {
  if (
    !data.allContentfulPage ||
    !Array.isArray(data.allContentfulPage.edges) ||
    data.allContentfulPage.edges.length === 0
  ) {
    return null;
  }

  const {
    title, content, metaTitle, metaDescription, image, snippets, url
  } = data.allContentfulPage.edges[0].node;

  let snippetsConverted = snippets?.internal?.content;

  if (snippetsConverted) {
    snippetsConverted = JSON.parse(snippetsConverted);
  }

  return (
    <>
      <Header />
      {
        image && image.fluid
          ? <div className="fullWidthContainer">
              <Img fluid={image.fluid} alt={image.title} />
            </div>
          : null
      }
      <MainContent pathname={location.pathname}>
        <Seo
          title={metaTitle}
          description={metaDescription}
          canonical={url === 'index' ? '' : url}
        />
        <h1>{title}</h1>
        <div className="markdown">
          <RichText
            data={content}
            snippets={snippetsConverted}
          />
        </div>
      </MainContent>
      <Footer />
    </>
  );
}

export default Page;

export const pageQuery = graphql`
  query($url: String!, $contentImgMaxWidth: Int!, $imgQuality: Int!) {
    allContentfulPage(filter: {url: {eq: $url}}) {
      edges {
        node {
          title
          content {
            raw
            references {
              ... on ContentfulAsset {
                cid: contentful_id
                fixed(
                  width: $contentImgMaxWidth,
                  quality: $imgQuality
                ) {
                  src
                }
                alt: title
                href: description
              }
            }
          }
          metaTitle
          metaDescription
          url
          image {
            title
            fluid(
              maxWidth: 1440,
              quality: $imgQuality
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          snippets {
            internal {
              content
            }
          }
        }
      }
    }
  }
`;
